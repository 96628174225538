@import '~@angular/cdk/overlay-prebuilt.css';

@mixin button($color, $text: null) {
  border-color: $color;
  background-color: $color;

  @if ($color == transparent) {
    color: $text;
  }

  &:hover, &:focus {
    background-color: lighten($color, 13%);
    border-color: lighten($color, 13%);
    @if ($text) {
      color: lighten($text, 13%);
    }
  }

  &:active {
    background-color: darken($color, 13%);
    border-color: darken($color, 13%);
    @if ($text) {
      color: darken($text, 13%);
    }
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;

  color: #333;
  padding: 20px;
}

$blue: #4990e2;
$green: #c8e044;
$brown: #a28664;

*, *::before, *::after {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

// Angular CDK Start

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
// Angular CDK End

.form {
  margin-bottom: 12px;

  .form-buttons {
    text-align: right;
  }
}

.control {
  label {
    display: block;
    font-weight: 400;
  }

  %input {
    display: block;
    width: 100%;
    padding: 6px;
    border: 1px solid #333;
  }

  input[type="text"],
  input[type="date"],
  input[type="number"],
  input[type="password"] {
    @extend %input;
  }

  textarea {
    @extend %input;
  }

  padding-bottom: 15px;
  .help {
    text-align: right;
    font-size: 11px;
    color: #444;
    margin-bottom: -15px;
  }
}

.link {
  color: $blue;
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
}

.btn {
  & + .btn {
    margin-left: 8px;
  }

  border: 3px solid #333;
  background-color: white;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;

  &.btn-primary {
    @include button($blue);
  }

  &.btn-secondary {
    @include button($green);
  }

  &.btn-tertiary {
    @include button($brown);
  }

  &.btn-link {
    @include button(transparent, $blue);

    &.btn-secondary {
      @include button(transparent, $green);
    }

    &.btn-tertiary {
      @include button(transparent, $brown);
    }

    &:disabled {
      background: transparent;
      border-color: transparent;
      color: grey;

      &:hover {
        background: transparent;
        border-color: transparent;
        color: grey;
      }
    }

    &.btn-link-inline {
      padding: 0;
    }
  }

  &:disabled {
    background: grey;
    border-color: grey;
    color: white;
    cursor: not-allowed;

    &:hover {
      background: grey;
      border-color: grey;
      color: white;
    }
  }
}

.spoiler {
  background: #333;
  color: transparent;

  &:hover {
    background: rgba(#333, 0.15);
    color: inherit;
  }
}

p {
  margin-top: 0;
}
